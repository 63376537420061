import { environment } from '@base/environments/environment';

export class PictureSource {
  // Clase propia de estilos
  public static BASEURL_CLOUDFRONT = environment.imageCloudfront;
  public static BASEURL_COUNTRY = environment.node;
  public static LOADING_LAZY = 'lazy';
  public static LOADING_EAGER = 'eager';
  public static DOCTYPE_CONCAT = '.';
  baseURL?: string | undefined;

  width?: string | undefined;

  // especifican el ancho de la imagen en píxeles.
  height?: string | undefined;
  // especifican la altura de la imagen en píxeles.
  loading?: string | undefined; // especifica que la imagen se cargará de forma perezosa (lazy) mientras el usuario se desplaza por la página.

  docType: string | undefined; // Tipo de extensión
  text: string | undefined; // Texto descriptivo a utilizar es de uso interno para optimizar la busqueda de información EJ: Publicidad
  link?: string | undefined; // Link de URL de destino
  className?: string | undefined;
}
